import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../components/common/Button';
import authService from '../../services/auth';
import queryString from 'query-string';
import { changeDocTitle, chPerm } from '../../components/helpers';
import MultiUserModal from './components/multiuserModal';

const RegisterModule = (props) => {
  const currentUser = authService.getCurrentUser();
  let query = props.location.search;
  let parsedQueryString = queryString.parse(query);

  React.useEffect(() => {
    changeDocTitle('Administrar');
  }, []);

  return (
    <>
      {false && currentUser.multiuser && <MultiUserModal />}
      <section className="Home">
        <div className="Home__content">
          <h1 className="Home__title">Bienvenido</h1>
          <p className="Home__intro">
            <strong style={{ display: 'block' }}>¡Hola {currentUser.name}!</strong>
            Bienvenido a la aplicación web de Comunidad.
          </p>
        </div>
        <div className="Home__buttons">
          {chPerm(['events']) && (
            <Link to="/registration">
              <Button text="Registro nuevo" disabled={false} />
            </Link>
          )}
          {chPerm(['crecer']) && (
            <Link
              to={`/checkin?event=crecer${
                parsedQueryString.id ? `&id=${parsedQueryString.id}` : ''
              }`}
            >
              <Button text="Ingreso crecer" disabled={false} />
            </Link>
          )}
          {chPerm(['events']) && (
            <Link
              to={`/checkin?event=Auditorio_Principal&eventSN=Auditorio_Principal${
                parsedQueryString.id ? `&id=${parsedQueryString.id}` : ''
              }`}
            >
              <Button text="Ingreso Servicio" disabled={false} />
            </Link>
          )}
          {chPerm(['events']) && (
            <Link to="/checkin/print-queue">
              <Button text="Cola de impresión" disabled={false} />
            </Link>
          )}
          {chPerm(['generaciones']) && (
            <Link to="/servicio/generaciones">
              <Button text="Ingreso Generaciones" disabled={false} />
            </Link>
          )}
          {chPerm(['salaExp', 'experienceCoord', 'volunteers']) && (
            <Link to="/voluntarios-servicio">
              <Button text="Ingreso voluntarios" disabled={false} />
            </Link>
          )}
        </div>
        {currentUser.profile === 1 && (
          <Link to="/admin" className="Home__link">
            Administrar
          </Link>
        )}
      </section>
    </>
  );
};

export default RegisterModule;
