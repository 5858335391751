
import { useContext, useEffect } from 'react';
import { DashboardComponent } from './components/styles/DashboardStyles';
import AdminLogin from './components/adminLogin';
import { AdminDashboardContext, AdminDashboardProvider } from './components/adminDashboardContext';
import { BrowserRouter as Router, Route, Switch, NavLink, Redirect } from 'react-router-dom';
import { getAVolunteer, getCrecerDates, getGuest } from './components/adminDashboardApiCalls';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUsers, faSeedling, faCalendar, faPoll, faStar } from '@fortawesome/free-solid-svg-icons';
import Groups from './components/Groups';
import Crecer from './components/Crecer'
import Experiencia from './components/Experiencia';
import Financiero from './components/Financiero'

const AdminDashboard = () => {

    const iconMapping = {
        perfil: faHome,
        grupos: faUsers,
        crecer: faSeedling,
        eventos: faCalendar,
        encuestas: faPoll,
        experiencia: faStar,
      };

    const {
        guest,
        setGuest,
        loginCredentials,
        setCrecerDates,
        setExperienceVolunteer,
        setLoginCredentials,
    } = useContext(AdminDashboardContext);

    let query = window.location.search;
    let parsedQueryString = queryString.parse(query);

    useEffect(() => {
        if (parsedQueryString.identification && parsedQueryString.phone) {
          getInfo(parsedQueryString);
        }
      }, []);
    
      const getInfo = async (inputs) => {
        await getGuest({ ...(inputs || {}), setGuest });
        setLoginCredentials(inputs);
      };
    
  
  return (
    <DashboardComponent>
      <Router>
        <div style={{ display: 'flex', height: '100vh' }}>
          {!!guest?.id ? (
            <>
              <div className="sidebar">
                <div className="containerImg">
                  <h4
                    style={{
                      marginTop: '10px',
                      marginBottom: '0px',
                      fontWeight: 300,
                      fontSize: '23px',
                    }}
                  >
                    Bienvenido {guest?.name} !
                  </h4>
                  <h4 style={{ marginTop: '2px', fontWeight: 200, fontSize: '16px' }}>
                    {guest?.status}
                  </h4>
                </div>
                <br></br>
                <ul className="menuList">
                  <li>
                    <NavLink exact to="/tablero/groups" activeClassName="activeExperiencia">
                      <span className="icon">
                        <FontAwesomeIcon icon={iconMapping.grupos} />
                      </span>
                      <span className="text">Grupos</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink exact to="/tablero/crecer" activeClassName="activeExperiencia">
                      <span className="icon">
                        <FontAwesomeIcon icon={iconMapping.crecer} />
                      </span>
                      <span className="text">Crecer</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink exact to="/tablero/experiencia" activeClassName="activeExperiencia">
                      <span className="icon">
                        <FontAwesomeIcon icon={iconMapping.experiencia} />
                      </span>
                      <span className="text">Experiencia</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink exact to="/tablero/finanzas" activeClassName="activeExperiencia">
                      <span className="icon">
                        <FontAwesomeIcon icon={iconMapping.perfil} />
                      </span>
                      <span className="text">Financiero</span>
                    </NavLink>
                  </li>
                  {/* Comenta o elimina las líneas no necesarias */}
                </ul>
              </div>
              <div style={{ flex: 1, padding: '0px' }}>
                <Switch>
                    <Route path="/tablero/groups" component={Groups} />
                    <Route path="/tablero/crecer" component={Crecer} />
                    <Route path="/tablero/experiencia" component={Experiencia} />
                    <Route path="/tablero/finanzas" component={Financiero} />
                    <Redirect exact from="/tablero" to="/tablero/groups" />
                </Switch>
              </div>
            </>
          ) : (
            <AdminLogin getInfo={getInfo} />
          )}
        </div>
      </Router>
    </DashboardComponent>
  );
};

const DashboardWithProvider = () => (
    <AdminDashboardProvider>
      <AdminDashboard />
    </AdminDashboardProvider>
  );


export default DashboardWithProvider;
