import { useEffect, useState } from 'react';
import moment from 'moment';
import ExperienceTeamAreaRow from '../../../components/common/adminTable/ExperienceTeamAreaRow';
import experienceService from '../../../services/experienceService';
import { Tab, Checkbox } from 'semantic-ui-react';
import { clickableStyles } from '../../../components/helpers';
import { Button } from 'semantic-ui-react';
import { cancelThisWeekendService } from './confirmationHelpers';

const ConfirmedVolunteers = (props) => {
  const { history, set_loading, loading, eventSlugname, serviceArea, section, role, handleDate } =
    props;
  const [volunteersByEvent, set_volunteersByEvent] = useState([]);
  const [volunteersRowChecks, set_volunteersRowChecks] = useState([]);
  const [showImgs, set_showImgs] = useState(true);

  useEffect(() => {
    getVolunteersByEvent();
  }, [eventSlugname, serviceArea, section, role, handleDate]);

  const getVolunteersByEvent = () => {
    set_loading?.(true);
    experienceService
      .getAllVolunteerServiceLogs({
        eventSlugname,
        serviceArea,
        section,
        role,
        startDate: handleDate?.startDate?.toDate(),
        endDate: handleDate?.endDate?.toDate(),
      })
      .then((response) => {
        set_loading(false);
        if (response.data.success) {
          set_volunteersByEvent(response.data.volunteersByEvent);
        }
      })
      .catch((err) => {
        set_loading?.(false);
      });
  };

  return (
    <div style={{ margin: '20px 0 0 0' }}>
      <Tab
        panes={volunteersByEvent.map((service) => ({
          menuItem: moment(service?.date).format('dddd, DD [de] MMM'),
          render: () => (
            <Tab.Pane attached={false} key={service.id}>
              {volunteersRowChecks.length > 0 && (
                <>
                  <p style={{ margin: '20px 0 0 0' }}>
                    Seleccionados: {volunteersRowChecks.length} -{' '}
                    <span style={clickableStyles} onClick={() => set_volunteersRowChecks([])}>
                      Limpiar
                    </span>
                  </p>
                  <Button
                    disabled={loading}
                    onClick={() =>
                      cancelThisWeekendService(
                        volunteersRowChecks.map((volunteer) => volunteer.volunteer_id),
                        service,
                        eventSlugname,
                        {},
                        getVolunteersByEvent,
                        set_loading
                      )
                    }
                  >
                    Cancelar seleccionados
                  </Button>
                </>
              )}
              <p>Total en pantalla: {service?.EventVolunteers?.length}</p>
              <div style={{ padding: '20px 0 0 0' }}>
                <Button primary onClick={() => set_showImgs(!showImgs)}>
                  {showImgs ? 'Ocultar' : 'Mostrar'} fotos
                </Button>
              </div>
              <table style={{ margin: '20px 0' }}>
                <thead>
                  <tr>
                    <th>
                      <Checkbox
                        checked={volunteersRowChecks?.length === service?.EventVolunteers?.length}
                        onChange={() => {
                          if (volunteersRowChecks?.length !== service?.EventVolunteers?.length) {
                            set_volunteersRowChecks(
                              service?.EventVolunteers?.map((volunteer) => ({
                                guest_id: volunteer?.Volunteer?.Guest?.id,
                                volunteer_id: volunteer?.Volunteer?.id,
                              }))
                            );
                          } else {
                            set_volunteersRowChecks([]);
                          }
                        }}
                      />
                    </th>
                    <th>Cedula</th>
                    {showImgs && <th>Foto</th>}
                    <th>Nombre</th>
                    <th>Celular</th>
                    <th>Area</th>
                    <th>Rol</th>
                    <th>Sirve en este evento</th>
                  </tr>
                </thead>
                <tbody>
                  {service?.EventVolunteers?.map((volunteer) => (
                    <ExperienceTeamAreaRow
                      set_volunteersRowChecks={set_volunteersRowChecks}
                      getVolunteersByEvent={getVolunteersByEvent}
                      volunteersRowChecks={volunteersRowChecks}
                      volunteer={volunteer?.Volunteer}
                      key={volunteer?.Volunteer?.id}
                      eventSlugname={eventSlugname}
                      set_loading={set_loading}
                      showImgs={showImgs}
                      loading={loading}
                      history={history}
                      service={service}
                      volunteerReserved
                      showArea
                    />
                  ))}
                </tbody>
              </table>
            </Tab.Pane>
          ),
        }))}
      />
    </div>
  );
};

export default ConfirmedVolunteers;
