import { css } from '@emotion/react';

export const GeneracionesParentComponent = css`
  .AdminTable.generaciones {
    .form {
      padding: 10px 0 0 0;
      label {
        display: block;
        &.profilePhoto {
          margin: 22px auto;
          cursor: pointer;
        }
      }
      &.registerParent {
        text-align: center;
      }
    }
    .parentMatch {
      .acudiente {
        padding: 10px 0 0 0;
        h3,
        p {
          margin: 0;
        }
        h2 {
          padding-top: 10px;
        }
        .profilePhoto {
          margin: 20px 0 0 0;
        }
      }
      .children {
        padding: 20px 0 0 0;
      }
    }
  }
  .generaciones {
    .profilePhoto {
      margin: 0px 0 22px 20px;
    }
    .birthdate {
      margin: 0 22px;
    }
  }
`;
