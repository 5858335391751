import { DashboardGroupsComponent } from './styles/DashboardGroupsComponent';

// src/components/Grupos.js
const Groups = () => {

  return (
    <DashboardGroupsComponent>
      <h2 style={{ color: '#009700', fontSize: '40px' }}>Grupos</h2>
    </DashboardGroupsComponent>
  );
};

export default Groups;
