import React from 'react';
import { Button } from 'semantic-ui-react';
import moment from 'moment-timezone';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import EventService from '../../services/eventService';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { DatesComponent } from '../../assets/styles/components/Dates';

const EventDates = (props) => {
  const [focused, set_focused] = React.useState(null);
  const [eventDates, set_eventDates] = React.useState([]);
  const [events, set_events] = React.useState([]);
  const [loading, set_loading] = React.useState(false);

  React.useEffect(() => {
    getEventDates();
  }, []);

  const getEventDates = () => {
    set_loading(true);
    EventService.getDates()
      .then((response) => {
        if (response.data.success) {
          set_loading(false);
          set_eventDates(response.data.dates);
          set_events([
            ...new Set(
              response.data.dates.map((date) =>
                date.event !== 'crecer2' && date.event !== 'crecer3' ? date.event : ''
              )
            ),
          ]);
        }
      })
      .catch((error) => {
        set_loading(false);
        if (error.response) {
          Swal.fire({ title: error.response.data.message });
        }
      });
  };

  const submit = (e) => {
    e.preventDefault();
    set_loading(true);
    let body = {
      dates: JSON.stringify(eventDates),
    };
    EventService.updateDates(body)
      .then((response) => {
        if (response.data.success) {
          set_loading(false);
          set_eventDates(response.data.dates);
          set_events([
            ...new Set(
              response.data.dates.map((date) =>
                date.event !== 'crecer2' && date.event !== 'crecer3' ? date.event : ''
              )
            ),
          ]);
        }
        Swal.fire({
          title: 'Fechas guardadas satisfactoriamente',
        });
      })
      .catch((error) => {
        set_loading(false);
        if (error.response) {
          Swal.fire({
            title: error.response.data.message,
          });
        }
      });
  };

  const handlePublishDate = (newDate, id) => {
    let dates = eventDates.map((date) =>
      date.id === id && newDate?._d ? { ...date, attendanceDate: newDate?._d } : date
    );
    set_eventDates(dates);
  };

  return (
    <DatesComponent>
      <form onSubmit={(e) => submit(e)} className="Registration__form">
        <h2>Fechas de los eventos de crecer</h2>

        {loading && <p>Cargando las fechas</p>}
        {events.map((event) => (
          <>
            <h3>{event}</h3>
            {eventDates
              .filter((date) => date.event === event)
              .map((date) => (
                <div className="date">
                  <label htmlFor={date.attendanceCode + date.event} style={{ paddingTop: '20px' }}>
                    {date.attendanceName}
                  </label>
                  <SingleDatePicker
                    date={moment(date.attendanceDate)}
                    id={date.attendanceCode + date.event}
                    onDateChange={(newDate) => handlePublishDate(newDate, date.id)}
                    focused={focused === date.attendanceCode + date.event}
                    onFocusChange={(a) =>
                      set_focused(a?.focused ? date.attendanceCode + date.event : false)
                    }
                    placeholder="dd/mm/aaaa"
                    block
                    numberOfMonths={1}
                    displayFormat="DD/MM/YYYY"
                  />
                </div>
              ))}
          </>
        ))}
        <div className="save">
          <Button disabled={loading} onClick={(e) => submit(e)}>
            Guardar fechas
          </Button>
        </div>
      </form>
    </DatesComponent>
  );
};

export default EventDates;
