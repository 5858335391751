import React from 'react';
import StepParentIdentification from './parentsMatch/stepParentIdentification';
import StepParentCreation from './parentsMatch/stepParentCreation';
import GeneracionesService from '../../../../services/generacionesService';
import Swal from 'sweetalert2';
import StepParentMatchChildren from './parentsMatch/stepParentMatchChildren';
import queryString from 'query-string';

const ParentGeneracionesMatch = (props) => {
  const [inputs, setInputs] = React.useState({});
  const [parent, set_parent] = React.useState({});
  const [generacionesGuests, set_generacionesGuests] = React.useState({});
  const [step, set_step] = React.useState(1);
  const [loading, set_loading] = React.useState(false);

  const handleInputs = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setInputs({ ...inputs, [name]: value });
  };

  let query = window.location.search;
  let parsedQueryString = queryString.parse(query);

  React.useEffect(() => {
    const { identification } = parsedQueryString;
    if (identification) {
      setInputs({ ...inputs, identification });
      getInfo(identification);
    }
  }, []);

  function getInfo(identification = inputs.identification) {
    if (!identification) return Swal.fire('Escribe la cedula del acudiente');

    set_loading(true);

    let body = { identification };
    GeneracionesService.GeneracionesParentMatch(body)
      .then((response) => {
        if (response.data.success) {
          set_loading(false);
          set_parent(response.data.parent);
          set_generacionesGuests(response.data.GeneracionesGuests);
          if (response?.data?.parent?.identification) set_step(3);
          else set_step(2);
        }
      })
      .catch((error) => {
        if (error.response) Swal.fire(error.response.data.message);
        set_loading(false);
      });
  }

  return (
    <section className="AdminTable generaciones">
      <h1>Ingreso Generaciones</h1>
      {step === 1 && (
        <StepParentIdentification
          loading={loading}
          inputs={inputs}
          handleInputs={handleInputs}
          getInfo={getInfo}
          setInputs={setInputs}
        />
      )}
      {step === 2 && (
        <StepParentCreation
          setInputs={setInputs}
          inputs={inputs}
          set_loading={set_loading}
          loading={loading}
          getInfo={getInfo}
          set_step={set_step}
          handleInputs={handleInputs}
        />
      )}
      {step === 3 && (
        <StepParentMatchChildren
          parent={parent}
          children={generacionesGuests}
          set_step={set_step}
          getInfo={getInfo}
          setInputs={setInputs}
        />
      )}
    </section>
  );
};

export default ParentGeneracionesMatch;
