import React from 'react';
import queryString from 'query-string';
import Barcode from 'react-barcode';
import printQueueService from '../../services/printQueue';

const PrintPage = (props) => {
  let query = props.location.search;
  let parsed = queryString.parse(query);

  function updateAPrintQueue(identification) {
    if (identification) {
      try {
        printQueueService.updateAPrintQueue(identification);
      } catch (error) {
        console.log(error);
      }
    }
  }

  React.useEffect(() => {
    setTimeout(() => {
      window.print();
    }, 500);

    setTimeout(() => {
      updateAPrintQueue(parsed.identification);
      window.close();
    }, 2000);
  }, []);

  if (parsed.category.includes('eneraciones'))
    return (
      <section className="print generaciones">
        <div className="children">
          <p className="code">{parsed.code.toUpperCase()}</p>
          <p className="child">
            {parsed.name}, <span className="age">{parsed.age}</span>
          </p>
          <p className="generaciones">{parsed.category?.replace(/_/g, ' ')}</p>
          <p className="event_date">{parsed.date}</p>
          <p className="acudiente">
            <span className="name">
              <strong>Acudiente:</strong> {parsed.parentFullname}
            </span>
          </p>
          <p className="acudiente">
            <span className="identification">
              <strong>Cedula:</strong>&nbsp;{parsed.parentIdentification}&nbsp;<strong>Cel:</strong>
              &nbsp;{parsed.phone}
            </span>
          </p>
          <p className="observations">{parsed.observations}</p>
        </div>
        <div className="parent">
          <p className="code">{parsed.code.toUpperCase()}</p>
          <p className="acudiente">
            <span className="name">
              <strong>Nombre acudiente:</strong> {parsed.parentFullname}
            </span>
          </p>
          <p className="event_date">{parsed.date}</p>
        </div>
      </section>
    );
  else
    return (
      <section className="print">
        <p>{parsed.name}</p>
        <div className="barcode">
          <Barcode value={parsed.identification} />
        </div>
      </section>
    );
};

export default PrintPage;
