import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import CoupleGroupRegistration from '../registration/groupRegistration';
import groupService from '../../../services/groupService';
import Swal from 'sweetalert2';

const AddGroupMember = (props) => {
  const { getInfo, isCouples, group, setOpen } = props;
  const [loading, set_loading] = React.useState(false);
  const [inputs, setInputs] = React.useState({});
  const [Step, setStep] = React.useState('first');

  React.useEffect(() => {
    if (Step === 'send') {
      const { activityName, id } = group;
      set_loading(true);

      let body = { ...inputs, group: id, activityName };

      groupService
        .saveGroupParticipant(body)
        .then((response) => {
          set_loading(false);
          if (response.data.success) {
            getInfo();
            setOpen(false);
          } else {
            Swal.fire('Error', response.data.message, 'error');
          }
        })
        .catch((err) => {
          set_loading(false);
        });
    }
  }, [Step]);

  return (
    <Modal onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open>
      <Modal.Header>Crear un participante</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          {Step === 'first' && (
            <CoupleGroupRegistration
              inputs={inputs}
              loading={loading}
              setInputs={setInputs}
              setStep={setStep}
              person="person1"
              next={isCouples ? 'second' : 'send'}
              strictNext
              leaderView
            />
          )}
          {Step === 'second' && (
            <CoupleGroupRegistration
              inputs={inputs}
              loading={loading}
              setInputs={setInputs}
              setStep={setStep}
              person="person2"
              next={'send'}
              strictNext
              leaderView
            />
          )}

          {Step === 'send' && <p>Enviando</p>}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button loading={loading} onClick={() => setOpen(false)}>
          Cancelar
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AddGroupMember;
