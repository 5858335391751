import styled from '@emotion/styled';
import backImage from './back_admin.png';

export const AdminLoginDashboardComponent = styled.div`
  width: 100%; /* Asegúrate de que el componente principal ocupe todo el ancho */
  height: 100vh; /* Asegúrate de que el componente principal ocupe toda la altura de la pantalla */
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${backImage}); /* Imagen de fondo */
  background-size: cover; /* Asegura que la imagen cubra todo el fondo */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  background-position: center; /* Centra la imagen de fondo */

  .Registration {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 500px;
    text-align: center;

    &__form {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    &__label {
      display: block;
      padding: 20px;
      font-size: 35px;
    }

    &__input {
      width: 90%;
      max-width: 400px;
      margin: 10px 0;

      &.error {
        border-color: red;
      }
    }

    &__error-message {
      color: red;
    }

    .captcha {
      margin: 20px 0;

      &.error {
        border-color: red;
      }
    }

    &__captcha-error {
      color: red;
    }
  }
`;
