import { Button, Modal } from 'semantic-ui-react';

const MultiUserModal = () => {
  const sendUser = () => {};
  return (
    <>
      <Modal open onClose={sendUser}>
        <Modal.Header>Bienvenido</Modal.Header>
        <Modal.Content>
          <p>Escribe tu nombre</p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={sendUser}>Entrar</Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default MultiUserModal;
