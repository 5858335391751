import { useState, useEffect } from 'react';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import churchForms from '../../services/churchForms';
import VolunteersRow from '../../components/common/adminTable/volunteersRow';
import { Select, Input, Button } from 'semantic-ui-react';
import {
  changeDocTitle,
  churchMinistries,
  experienceVolunteersRoles,
  experienceVolunteersSections,
} from '../../components/helpers';
import authService from '../../services/auth';
import ReportButton from '../admin/ReportButton';
import queryString from 'query-string';
import CreateVolunteer from '../experience/volunteersLogs/VolunteerForm';
import { Link } from 'react-router-dom';

const AllVolunteers = (props) => {
  const [volunteers, set_volunteers] = useState([]);
  const [loading, set_loading] = useState(true);
  const [showVolunteer, set_showVolunteer] = useState(null);
  const [inputs, setInputs] = useState({});
  const [endPage, set_endPage] = useState(false);
  const [showImgs, set_showImgs] = useState(false);
  const [currentPage, set_currentPage] = useState(1);
  const [showNewVolunteerForm, set_showNewVolunteerForm] = useState(false);
  const currentUser = authService.getCurrentUser();

  let query = props.location.search;
  let parsedQueryString = queryString.parse(query);

  useEffect(() => getInfo(), [inputs.serviceArea, inputs.volunteerRole, inputs.volunteerSection]);

  useEffect(() => {
    changeDocTitle('Voluntarios');
  }, []);

  const onEnter = (e) => {
    if (e.key === 'Enter') getInfo();
  };

  const loadMore = () => {
    set_currentPage(currentPage + 1);
  };

  useEffect(() => {
    if (currentPage !== 1) getInfo(true);
  }, [currentPage]);

  const serviceArea = inputs.serviceArea || '';
  const volunteerRole = inputs.volunteerRole || '';
  const volunteerSection = inputs.volunteerSection || '';

  function getInfo(page = false) {
    set_endPage(false);
    set_loading(true);

    let body = {
      keyword: inputs.keyword || parsedQueryString.keyword,
      currentPage,
      volunteerRole,
      volunteerSection,
    };

    if (!page) {
      set_currentPage(1);
      body.currentPage = 1;
    }

    churchForms
      .getVolunteerForm({ body, serviceArea })
      .then((response) => {
        if (response.data.success) {
          set_loading(false);
          props.history.push('?keyword=');

          if (page && body.currentPage > 1)
            set_volunteers([...volunteers, ...response.data.volunteers]);
          else set_volunteers(response.data.volunteers);

          if (response.data.volunteers.length === 0) set_endPage(true);
        } else {
          set_loading(null);
        }
      })
      .catch((error) => {
        if (error.response) Swal.fire(error.response.data.message);
        set_loading(false);
      });
  }

  const selectHandle = (a, { value, name }) => {
    setInputs({ ...inputs, [name]: value });
  };

  return (
    <section className="AdminTable">
      {!showVolunteer && (
        <>
          <div className="AdminTable__content">
            <div>
              <Link to="/admin?folder=volunteers">
                <Button primary>Atrás</Button>
              </Link>
              <Button primary onClick={() => getInfo()}>
                Recargar
              </Button>
              <ReportButton
                URL={`/experience/VolunteersReport${serviceArea ? `/${serviceArea}` : ''}`}
                fileName={`reporte_voluntarios.xlsx`}
                event="Descargar Reporte"
              />
            </div>
            <Input
              loading={loading}
              name={'keyword'}
              onChange={(e) => setInputs({ ...inputs, keyword: e.target.value })}
              value={inputs.keyword}
              onKeyPress={onEnter}
              style={{ width: '45%' }}
              placeholder="Buscar..."
            />
            <Select
              placeholder="Selecciona el area para consultar."
              style={{
                display: 'inline-block',
                margin: ' 7px 0',
                width: '45%',
              }}
              onChange={selectHandle}
              name="serviceArea"
              loading={loading}
              options={[
                { key: '', value: '', text: 'Todos' },
                ...churchMinistries.sort().map((a) => {
                  return { key: a, value: a, text: a };
                }),
              ]}
            />
            <Select
              placeholder="Selecciona el rol para consultar."
              style={{
                display: 'inline-block',
                margin: ' 7px 0',
                width: '45%',
              }}
              onChange={selectHandle}
              name="volunteerRole"
              loading={loading}
              options={[
                { key: '', value: '', text: 'Todos' },
                ...experienceVolunteersRoles.sort().map((a) => {
                  return { key: a, value: a, text: a };
                }),
              ]}
            />
            <Select
              placeholder="Selecciona la sección para consultar."
              style={{
                display: 'inline-block',
                margin: ' 7px 0',
                width: '45%',
              }}
              onChange={selectHandle}
              name="volunteerSection"
              loading={loading}
              options={[
                { key: '', value: '', text: 'Todos' },
                ...['Pendientes', ...experienceVolunteersSections(inputs?.serviceArea)].map((a) => {
                  return { key: a, value: a, text: a?.replace(/_/g, ' ') };
                }),
              ]}
            />
          </div>
          <p>Total en pantalla: {volunteers.length}</p>
          <div>
            <Button primary onClick={() => set_showImgs(!showImgs)}>
              {showImgs ? 'Ocultar' : 'Mostrar'} fotos
            </Button>
            <Button onClick={() => set_showNewVolunteerForm(true)}>Añadir un voluntario</Button>
            {showNewVolunteerForm && (
              <CreateVolunteer
                mainInputs={inputs}
                getInfo={getInfo}
                set_loading={set_loading}
                loading={loading}
                set_showVolunteerForm={set_showNewVolunteerForm}
              />
            )}
          </div>
        </>
      )}
      <div>
        <table>
          {!showVolunteer && (
            <thead>
              <tr>
                <th>Cedula</th>
                {showImgs && <th>Foto</th>}
                <th>Nombre</th>
                <th>Apellido</th>
                <th>Celular</th>
                <th>Area de Servicio</th>
                <th>Rol</th>
                <th>Equipo</th>
                <th>Activo</th>
                <th>Ver</th>
              </tr>
            </thead>
          )}
          <tbody>
            {volunteers.map((Volunteer) => (
              <VolunteersRow
                Volunteer={Volunteer}
                getInfo={getInfo}
                currentUser={currentUser}
                set_showVolunteer={set_showVolunteer}
                showImgs={showImgs}
                set_loading={set_loading}
                loading={loading}
              />
            ))}
          </tbody>
        </table>
        {!showVolunteer && (
          <div style={{ textAlign: 'center', margin: '20px 0 ' }}>
            <Button disabled={endPage || loading} onClick={() => loadMore()}>
              {loading ? 'Cargando...' : 'Cargar más'}
            </Button>
          </div>
        )}
      </div>
    </section>
  );
};

export default AllVolunteers;
