import { Link } from 'react-router-dom';
import Button from '../../components/common/Button';

const PublicPage = () => {
  return (
    <>
      <section className="PublicHome">
        <h1 className="PublicHome__title">Bienvenido</h1>
        <div className="PublicHome__content">
          <h2>Tu perfil en Comunidad</h2>
          <p className="PublicHome__intro">
            <strong style={{ display: 'block', padding: '20px 0 0 0' }}>
              Revisa tu perfil en Comunidad, tus pasos de crecer, las proximas clases, tu grupo, y más.
            </strong>
            <Link to="/perfil/crecer">
              <Button text="Entra a tu perfil" disabled={false} />
            </Link>
          </p>
        </div>
        <div className="PublicHome__content">
          <h2>Campus Medellín</h2>
          <strong style={{ display: 'block', padding: '20px 0 0 0' }}>
            Regístrate en uno de nuestros eventos
          </strong>
          <Link to="/servicio/reservacion">
            <Button text="Registrate a una reunión" disabled={false} />
          </Link>
        </div>
      </section>
    </>
  );
};

export default PublicPage;
