import React from 'react';
import Form from '../../components/form/Form';
import guestService from '../../services/guest';
import printQueueService from '../../services/printQueue';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import CrecerCheckin from '../../components/eventsLogin/crecer';
import authService from '../../services/auth';
import { Button } from 'semantic-ui-react';
import EntryCheckpoint from '../church-members/church-service/reservation/entryCheckpoint';
import moment from 'moment';
import SeminaryCheckin from '../../components/eventsLogin/seminary';
import { CheckInComponent } from '../../assets/styles/components/CheckIn';

const GuestData = (props) => {
  const {
    Attendant,
    handleInputs,
    resetForm,
    set_errorData,
    errorData,
    set_Attendant,
    handleDropDown,
    getAttendantInfo,
  } = props;
  const currentUser = authService.getCurrentUser();
  const [showForm, set_showForm] = React.useState(false);
  const [loading, set_loading] = React.useState(false);

  function updateAttendant(e) {
    e.preventDefault();

    const { name, lastName, phone, identification, email, gender, birthdate } = Attendant;

    if (!name || !lastName || !phone || !identification || !birthdate) {
      set_errorData('Todos los campos son obligatorios');
      return;
    }
    set_loading(true);

    guestService
      .updateGuest(Attendant.id, {
        name,
        lastName,
        phone,
        identification,
        email,
        gender,
        birthdate,
      })
      .then((response) => {
        if (response.data.success) {
          set_errorData(response.data.message);
          getAttendantInfo(Attendant.identification);
        }
        set_loading(false);
      })
      .catch((error) => {
        if (error.response && error.response.data) set_errorData(error.response.data.message);
        set_loading(false);
      });
  }

  function addToAPrintQueue() {
    const { name, lastName, phone } = Attendant;

    printQueueService
      .addToAPrintQueue({
        name: `${name} ${lastName}`,
        identification: phone,
      })
      .then((response) => {
        if (response.data.success) {
          Swal.fire(response.data.message);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) Swal.fire(error.response.data.message);
      });
  }

  return (
    <CheckInComponent>
      <Button primary onClick={() => resetForm()}>
        Atrás
      </Button>
      <h1 className="CheckIn__title">Asistente</h1>
      {showForm ? (
        <div style={{ textAlign: 'center' }}>
          <Form
            saveTag={'Actualizar'}
            Attendant={Attendant}
            handleInputs={handleInputs}
            handleDropDown={handleDropDown}
            errorInputs={{}}
            sendInForm
            showGender
            showBirthdate
            createdAt
            loadedData={loading}
            submit={updateAttendant}
          />
        </div>
      ) : (
        <div className="info">
          {Attendant.photo && (
            <div className="profilePhoto" style={{ backgroundImage: `url(${Attendant.photo})` }} />
          )}
          <div className={`data ${Attendant.photo ? 'pic' : ''}`}>
            <p>
              <strong>Nombre:</strong>
              {Attendant.name}&nbsp;{Attendant.lastName}&nbsp;
            </p>
            <p>
              <strong>Cedula:</strong>
              {Attendant.identification}&nbsp;
            </p>
            <p>
              <strong>Celular:</strong>
              {Attendant.phone}&nbsp;
            </p>
            <p>
              <strong>Edad:</strong>
              {moment().diff(Attendant.birthdate, 'years')}&nbsp;
            </p>
          </div>
        </div>
      )}
      <Button className="modify" size="mini" onClick={() => set_showForm(!showForm)}>
        {showForm ? 'Ocultar' : 'Modificar'}
      </Button>
      <p style={{ color: 'red' }}>{errorData}</p>
      {currentUser.status.includes('events') && (
        <Button onClick={() => addToAPrintQueue()}>Imprimir escarapela</Button>
      )}
      {props.Attendant.CrecerConectarSteps && (
        <>
          <CrecerCheckin
            Attendant={Attendant}
            location={props.location}
            eventDates={props.eventDates}
            set_Attendant={set_Attendant}
            resetForm={resetForm}
          />
          <SeminaryCheckin
            Attendant={Attendant}
            location={props.location}
            eventDates={props.eventDates}
            set_Attendant={set_Attendant}
            resetForm={resetForm}
          />
        </>
      )}
      {props.Attendant.reserved && (
        <EntryCheckpoint
          getAttendantInfo={getAttendantInfo}
          Attendant={Attendant}
          reserved={props.Attendant.reserved}
          set_errorData={set_errorData}
        />
      )}
    </CheckInComponent>
  );
};

export default GuestData;
