import { createContext, useState } from 'react';

const AdminDashboardContext = createContext({});

const AdminDashboardProvider = ({ children }) => {
  const [guest, setGuest] = useState({});
  const [crecerDates, setCrecerDates] = useState({});
  const [experienceVolunteer, setExperienceVolunteer] = useState({});
  const [loginCredentials, setLoginCredentials] = useState({});

  const dataState = {
    guest,
    setGuest,
    loginCredentials,
    setLoginCredentials,
    crecerDates,
    setCrecerDates,
    experienceVolunteer,
    setExperienceVolunteer,
  };

  return <AdminDashboardContext.Provider value={dataState}>{children}</AdminDashboardContext.Provider>;
};

export { AdminDashboardContext, AdminDashboardProvider };
