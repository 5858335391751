import { DashboardCrecerComponent } from './styles/DashboardCrecerComponent';

// src/components/Grupos.js
const Crecer = () => {

  return (
    <DashboardCrecerComponent>
      <h2 style={{ color: '#009700', fontSize: '40px' }}>Crecer</h2>
    </DashboardCrecerComponent>
  );
};

export default Crecer;
