import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './routes/App';
import { Global, css } from '@emotion/react';
import { AppStyles } from './assets/styles/AppStyles';
import { localhostname } from './components/helpers';

if (window.location.hostname !== localhostname) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],

    tracesSampleRate: process.env.REACT_APP_SENTRY_TRACES_RATE,
    replaysSessionSampleRate: process.env.REACT_APP_SENTRY_TRACES_RATE,
    replaysOnErrorSampleRate: 1.0,
  });
}
ReactDOM.render(
  <>
    <Global
      styles={css`
        ${AppStyles}
      `}
    />
    <App />
  </>,
  document.getElementById('App')
);
