import { DashboardGroupsComponent } from './styles/DashboardGroupsComponent';

// src/components/Grupos.js
const Groups = () => {
  const groupIn = {
    name: 'Una Nueva Vida',
    lider: 'Carlos & Daniela',
    date: 'Sabado 3pm',
    phone: '310 890 8970',
    mode: 'Hibrido',
  };

  const yourGroup = [
    {name: 'Grupo de Hombres'},
    {name: 'Grupo de Mujeres Jovenes'},
    {name: 'Grupo de Jovenes'},
  ]

  return (
    <DashboardGroupsComponent>
      <h3 style={{ marginBottom: 0, fontSize: '35px', fontWeight: '500' }}>Estos son tus</h3>
      <h2 style={{ color: '#009700', fontSize: '40px' }}>Grupos</h2>
      <div className="columnsGroup">
        <div className="columnGroup">
          <div className="container-group">
            <p style={{ fontSize: '18px', marginBottom: 3, color: '#878787' }}>
              Haces parte del grupo
            </p>
            <div className="container-group-in">
              <p style={{ fontSize: '25px', fontWeight: 600, color: '#009700', marginBottom: 0 }}>
                {groupIn.name}
              </p>
              <p style={{ marginBottom: 0 }}>{groupIn.lider}</p>
              <p style={{ fontSize: '18px', color: '#a3a3a3' }}>{groupIn.phone}</p>
              <div className="columnsGroup">
                <div>
                  <p className="subtitle">Modalidad</p>
                  <p>{groupIn.mode}</p>
                </div>
                <div>
                  <p className="subtitle">Horario</p>
                  <p>{groupIn.date}</p>
                </div>
              </div>
            </div>

            <br></br>
            <p style={{ fontSize: '18px', marginBottom: 3, color: '#878787' }}>
              Estos son tus grupos.
            </p>
            <br></br>
            <div className="your-group">
              {yourGroup.map((group, index) => (
                <button key={index} className="group-button">
                  {group.name}
                </button>
              ))}
            </div>

          </div>
        </div>
      </div>
    </DashboardGroupsComponent>
  );
};

export default Groups;
