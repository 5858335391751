import styled from '@emotion/styled';

export const DashboardGroupsComponent = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fafdff;
  border-radius: 20px 0px 0px 0px;

`;

