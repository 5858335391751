import styled from '@emotion/styled';

export const DatesComponent = styled('div')`
  width: 80%;
  margin: 50px 0;
  .Registration__form {
    padding: 20px;
  }
  .date {
    width: 20%;
    display: inline-block;
    margin: 10px 0;
  }
  .save {
    background-color: white;
    position: fixed;
    bottom: 0;
    right: 125px;
    width: 260px;
    button {
      width: 200px;
    }
  }
  @media only screen and (max-width: 800px) {
    width: 95%;
    .date {
      width: 33.33%;
    }
  }
  @media only screen and (max-width: 600px) {
    .date {
      width: 50%;
    }
  }
`;
