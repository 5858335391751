import { css } from '@emotion/react';

export const HomeComponent = css`
  .admin {
    align-items: center;
    background-color: white;
    border-radius: 22px;
    grid-column-gap: 1rem;
    grid-template-areas: 'content buttons' 'link link';
    max-width: 80vw;
    padding: 1.1rem;
    * {
      text-align: center;
    }
    button {
      margin: 5px !important;
    }
  }
  .Home {
    display: grid;
    grid-template: auto 100px / 2fr 1fr;
    align-items: center;
    background-color: white;
    border-radius: 22px;
    grid-column-gap: 1rem;
    grid-template-areas: 'content buttons' 'link link';
    max-width: 80vw;
    padding: 1.1rem;

    &__content {
      grid-area: content;
    }

    &__buttons {
      grid-area: buttons;
      text-align: center;
    }

    &__title {
      color: #2596c2;
      font-size: 2.5rem;
    }

    &__button {
      background-color: #2596c2;
      border-radius: 0.6rem;
      color: white;
      display: flex;
      margin: 1rem auto;
      max-width: 300px;
      height: 47px;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      text-align: center;
      text-decoration: none;
      transition: 300ms;
      grid-area: buttons;
      &:hover {
        background-color: black;
      }
    }
    &__link {
      grid-area: link;
      text-align: center;
      border-top: 0.5px solid lightgray;
      color: gray;
      padding: 1rem 0;
      align-self: end;
    }
  }
  .PublicHome {
    background-color: white;
    border-radius: 22px;
    padding: 20px 40px;
    min-width: 70%;
    width: 80%;

    &__title {
      color: #2596c2;
      font-size: 2.5rem;
    }

    &__content {
      width: 50%;
      display: inline-block;
      vertical-align: top;
    }
  }
  @media only screen and (max-width: 600px) {
    .Home {
      grid-template: repeat(3, auto) / 1fr;
      grid-template-areas: 'content' 'buttons' 'link';
      justify-content: center;
    }
    .PublicHome {
      width: 90%;
      &__content {
        width: 100%;
        padding: 20px 0 0 0;
      }
    }
  }
`;
