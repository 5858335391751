import { css } from '@emotion/react';

export const BarcodeComponent = css`
  .print {
    p {
      margin: 0;
      text-align: center;
    }
    .barcode {
      text-align: center;
    }
    &.generaciones {
      .barcode {
        margin: -8px 0 0 0;
      }
      .acudiente {
        margin: -8px 0 0 0;
        .name {
          font-size: 17px;
        }
      }
      .generaciones {
        font-size: 16px;
        margin: -5px 0 0px 0;
      }
      .observations {
        font-size: 12px;
        min-height: 20px;
      }
      .child {
        margin: 5px 0;
        font-size: 20px;
        .age {
          margin: -6px 0 3px 0;
          font-size: 15px;
        }
      }
      .code {
        font-weight: 600;
        font-size: 20px;
      }
      .children {
        max-height: 170px;
        min-height: 170px;
        overflow: hidden;
        .event_date {
          font-size: 14px;
          margin: -5px 0px 5px 0;
        }
        .code {
          margin: 0 0 -6px 0;
        }
      }
      .parent {
        max-height: 170px;
        min-height: 170px;
        overflow: hidden;
        .event_date {
          margin: 0;
          font-size: 19px;
        }
        .code {
          margin: 0 0 5px 0;
        }
      }
    }
    .parent {
      margin: 40px 0 0 0;
    }
    span.identification {
      font-size: 15px;
      display: block;
    }
  }
`;
