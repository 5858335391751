import { Button, Image } from 'semantic-ui-react';
import { avoCaptConstructor, profileImage } from '../../helpers/index';

import { useState } from 'react';
import VolunteerForm from '../../../containers/experience/volunteersLogs/VolunteerForm';

const VolunteersRow = (props) => {
  const [showVolunteerForm, set_showVolunteerForm] = useState(false);
  const { loading, set_loading, Volunteer, getInfo, showImgs } = props;

  const { name, identification, lastName, phone, serviceArea, role, section, suspended } =
    Volunteer;

  return (
    <tr>
      <td>
        <p style={{ margin: '0' }}>{identification}</p>
      </td>
      {showImgs && (
        <td>
          <Image src={profileImage(Volunteer)} rounded size="small" />
        </td>
      )}
      <td>
        <p style={{ margin: '0' }}>{name}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{lastName}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{phone}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{serviceArea}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>
          {role === 'Coordinador' ? (
            <a
              target="_blank"
              rel="noreferrer"
              href={`/experiencia/voluntarios?phone=${phone}&identification=${identification}&serviceArea=${serviceArea}&ac=${avoCaptConstructor(
                identification
              )}`}
            >
              <Button basic color="black" size="mini">
                {role}
              </Button>
            </a>
          ) : (
            role
          )}
        </p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{section?.replace(/_/g, ' ')}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{suspended ? 'No' : 'Si'}</p>
      </td>
      <td>
        <Button disabled={loading} onClick={() => set_showVolunteerForm(true)}>
          Editar
        </Button>
        {showVolunteerForm && (
          <VolunteerForm
            prevVolunteerInputs={Volunteer}
            set_showVolunteerForm={set_showVolunteerForm}
            set_loading={set_loading}
            getInfo={getInfo}
            loading={loading}
          />
        )}
      </td>
    </tr>
  );
};

export default VolunteersRow;
