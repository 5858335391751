import React from 'react';
import { Button, Modal, Select } from 'semantic-ui-react';
import { formSelectService, clickableStyles, copytoClipboard } from '../../../components/helpers';
import Swal from 'sweetalert2';

const GenerateLinksModal = (props) => {
  const { previousEventsNames } = props;
  const [nameselected, set_nameselected] = React.useState('');

  const selectedlink = `https://info.comunidadmde.com/servicio/reservacion?event=${nameselected}&id=event`;

  return (
    <>
      <Modal closeIcon trigger={<Button>Links de reserva</Button>}>
        <Modal.Header>
          Selecciona un tipo de evento o auditorio para generar el link de reserva
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <div style={{ margin: '0 10px 0 20px' }}>
              {formSelectService({
                Select,
                label: 'Evento o Auditorio',
                name: 'eventSlugname',
                handler: (a, { value, name }) => set_nameselected(value),
                value: nameselected,
                options: previousEventsNames,
              })}
              {nameselected && (
                <>
                  <Button onClick={() => window.open(selectedlink, '_blank')}>Ir</Button>
                  <p
                    onClick={() => copytoClipboard({ content: selectedlink, Swal })}
                    style={{ margin: '10px', ...clickableStyles }}
                  >
                    {selectedlink}
                    <br />
                    <small>Click para copiar</small>
                  </p>
                </>
              )}
            </div>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default GenerateLinksModal;
