import proxybase from './proxybase';

class groupService {
  getGroupAttendant(body) {
    return proxybase
      .post(`/groups/couple-groups`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  getAllGroups(body) {
    return proxybase
      .post(`/groups/all-couple-groups`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  getAllGroupsByLeader(body) {
    return proxybase
      .post(`/groups/all-couple-groups-by-leaders`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  saveGroupParticipant(body) {
    return proxybase
      .post(`/groups/register-couple-groups`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  attendanceRegister(body) {
    return proxybase
      .post(`/groups/register-couple-attendance`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  editGroup(body) {
    return proxybase
      .post(`/groups/edit-couple-group`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  editGroupLeaders(body) {
    return proxybase
      .post(`/groups/edit-group-leaders`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  createGroup(body) {
    return proxybase
      .post(`/groups/create-couple-group`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  complianceForms(body) {
    return proxybase
      .post(`/groups/compliance-form`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  allComplianceForms(body) {
    return proxybase
      .post(`/groups/all-compliance-forms`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  GroupMemberDelete(body) {
    return proxybase
      .post(`/groups/delete-couple-member`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  GroupMemberAccept(body) {
    return proxybase
      .post(`/groups/accept-couple-member`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  PendingGroupPeople(body) {
    return proxybase
      .post(`/groups/get-pending-people`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
const groupServiceInstance = new groupService();
export default groupServiceInstance;
